import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


import './Error404.css';

import ContactCard from '../../../shared/components/ContactCard/ContactCard';

import aboutImg from '../../../utils/assets/notFound/aboutImg.png';
import priceImg from '../../../utils/assets/notFound/priceImg.png';
import contactImg from '../../../utils/assets/notFound/contactImg.png';
import cancelImg from '../../../utils/assets/notFound/cancelImg.png';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Error404 = () => {
    
    const history = useHistory();
    const handleHomeClick = ( sectionId ) =>{
        sessionStorage.setItem('sectionName', sectionId);
        history.push('/');
    };
   


    return (

        <section className="Error404__main-container">
            <div className="Error404__error-container">
                <div className="Error404__error-text-container">
                    <div className="Error404__error-title-conainter">
                        <h1>Error 404</h1>
                    </div>
                    <div className="Error404__error-description">
                        <h2>Find what you are looking for!</h2>
                        <p>
                        The item may have been moved or may no longer be available.
                        In the blink of an eye, you can find what you need. Here are
                        some useful links.
                        </p>
                    </div>
                </div>
            </div>
            <div className="Error404__pages-container">
                <div className="Error404__pages">
                    <a  href='/' onClick={() =>  handleHomeClick('about-us')} className="Error404__about-container" style={{backgroundImage: `url(${aboutImg})`}} >
                        <div className="Error404__about-text-container">
                            <h1>About</h1>
                        </div>
                    </a>

                    <a href='/' onClick={() =>  handleHomeClick('price')} className="Error404__price-container" style={{backgroundImage: `url(${priceImg})`}}>
                        <div className="Error404__price-text-container">
                            <h1>Price</h1>
                        </div>
                    </a>

                    <a href='/' onClick={() => handleHomeClick('contact-us')} className="Error404__contact-container" style={{backgroundImage: `url(${contactImg})`}}>
                        <div className="Error404__contact-text-container">
                            <h1>Contact</h1>
                        </div>
                    </a> 

                    <Link to='/cancel-service' className="Error404__cancel-container" style={{backgroundImage: `url(${cancelImg})`}} >
                        <div className="Error404__cancel-text-container">
                            <h1>Cancel</h1>
                        </div>
                    </Link>


                </div>
            </div>
            <div className="Error404__contact-card-component-container">
               
                    <ContactCard />
               
            </div>
        </section>

    );
}

export default Error404;