import React from 'react';
import './PaymentForm.css'
import CheckoutForm from './components/CheckoutForm/CheckoutForm';






const PaymentForm = () => {
    return (
    
        <section className="PaymentForm__billing-address-container">
            <div className='PaymentForm__billing'>
                <CheckoutForm />
            </div>
        </section>
    
);
};

export default PaymentForm;
