import React from "react";
import './BrandImgSection.css';

import forbes from '../../../utils/assets/home/forbes.png';
import people from '../../../utils/assets/home/people.png';
import time from '../../../utils/assets/home/time.png';
import elle from '../../../utils/assets/home/elle.png';
import shape from '../../../utils/assets/home/shape.png';
import vogue from '../../../utils/assets/home/vogue.png';
import workingMother from '../../../utils/assets/home/workingMother.png';
import bazaar from '../../../utils/assets/home/bazzar.png';
import mensJournal from '../../../utils/assets/home/mensJournal.png';
import womensHealth from '../../../utils/assets/home/womensHealth.png';
import cosmopolitam from '../../../utils/assets/home/cosmopolitan.png';
import marieClaire from '../../../utils/assets/home/marieClaire.png';


const BrandImgSection = props => {

    return (
        <section className="BrandImgSection__main-container">
            <div className="BrandImgSection__title-container">
                <h1>The most popular magazines now</h1>
            </div>
            <div className="BrandImgSection__icons-container">
                <div className="BrandImgSection__icon-container">
                    <img src={forbes} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container">
                    <img src={people} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container">
                    <img src={time} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container">
                    <img src={elle} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container">
                    <img src={shape} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container">
                    <img src={vogue} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container" >
                    <img src={workingMother} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container">
                    <img src={bazaar} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container">
                    <img src={mensJournal} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container">
                    <img src={womensHealth} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container">
                    <img src={cosmopolitam} alt="" className="BrandImgSection__icon"/>
                </div>

                <div className="BrandImgSection__icon-container">
                    <img src={marieClaire} alt="" className="BrandImgSection__icon"/>
                </div>

            </div>
        </section>
    );
};

export default BrandImgSection;