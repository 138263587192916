import React from "react";
import './WelcomeSection.css';

const WelcomeSection = ( {props} ) => {

    return(
        <section className="WelcomeSection__main-section-container" style={{backgroundImage: `url(${props.lengendBackground})`}} id="home"> 
            <CardInfo props={props}></CardInfo>
        </section> 
    );
};


const CardInfo = ( { props }) =>{
    const phoneNumber = "8884131548";

    const goToHandle = ( sectionId ) => {


        if( window.location.pathname === '/privacy-policy' ||window.location.pathname === '/terms-of-service' ){
            window.location.href = `tel:${phoneNumber}`;
        }
        else if( window.location.pathname === '/'){
            const sectionElement = document.getElementById(sectionId);
            const windowHeight = window.innerHeight;
            const docScrollTop = window.scrollY || document.documentElement.scrollTop;
            const sectionTop = sectionElement.getBoundingClientRect().top + docScrollTop;
            const centeredPosition = sectionTop - (windowHeight / 2);
            const scrollToPosition = centeredPosition + 280; 
            window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
        }
    }
    
    
    return(
       
        <div className="WelcomeSection__main-card-info-container">

            <div className="WelcomeSection__main-title-container">
                <h2 className="text-left">{props.cardTitle}</h2>
            </div>
            <div className="WelcomeSection__main-body-container">
                <p>
                {props.cardBodyText}
                </p>
            </div>
            {
                props.cardButtonText.trim() !== "" ? (
                    <div className="WelcomeSection__main-button-container">
                        <div className="WelcomeSection__inner-button-container">
                            <a href="" onClick={(e) => { e.preventDefault(); goToHandle('contact-us')}}>
                                {props.cardButtonText}
                            </a>
                        </div>
                    </div>
                ) : (
                    <div className="WelcomeSection__button-container" style={{ minWidth : '10em'}}>
                    </div>
                )
            }
        </div>
     
    )
}


export default WelcomeSection;