import React from "react";
import './ProductDescription.css';

const ProductDescription = ({ props }) => {


    let paragraphContent = (
        <>
            <p>
                <b>Learn about the latest trends in fashion, beauty, business and
                    more.</b> With our membership program, you can get access to
                exclusive magazines at a lower price than ever before.
            </p>
            <p>
                Select up to four different titles to be mailed to your home, if
                there is a specific magazine you would like and it is not in our
                selection, simply call Customer Service and we will get it for
                you.
            </p>
        </>
    );




return (
    <section className="ProductDescription__main-container" id="about-us">
        <div className="ProductDescription__explanation-container">
            <div className="ProductDescription__title-container">
                <h1>{props.title}</h1>
            </div>
            <div className="ProductDescription__explanation-container text-left">
                {paragraphContent}
            </div>
        </div>
    

        <div className="ProductDescription__img-container">
            <div className="ProductDescription__img-inner-container">
                <img src={props.img} alt={props.title} className="ProductDescription__img" />
            </div>
        </div>
    </section>
);

}

export default ProductDescription;