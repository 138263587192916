import React from "react";

import './ContactCard.css';



import contactCardIcon from '../../../utils/assets/icons/customer-service-icon.png';
import emailServiceIcon from '../../../utils/assets/icons/email-icon.png';


const ContactCard = () => {

    return (


        <div className="ContactCard__information-container">
            <div className="ContactCard__contact-information">
                <div className="ContactCard__service-number-contact-container">
                    <div className="ContactCard__person-support-icon-container">
                        <img src={contactCardIcon} alt="Customer Service Icon"/>
                    </div>
                    <div className="ContactCard__service-number-container">
                    <p>
                    (888) 413-1548
                    </p>
                    </div>
                </div>

                <div className="ContactCard__email-contact-container">
                    <div className="ContactCard__email-icon-container">
                    <img src={emailServiceIcon} alt="Email Service Icon"/>
                    </div>
                    <div className="ContactCard__email-container">
                        <p className="ContactCard__break-word">
                        help@internationalreadersllc.com       
                        </p>
                    </div>
                </div>

            </div>
            <div className="ContactCard__description-information">
                <p>
                If you need information or assistance with billing issues. <b>Please
                contact us!</b>
                </p>
            </div>
        </div>


    );
};

export default ContactCard;