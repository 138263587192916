import React, {useEffect} from 'react';
import checkoutBannerImg from '../../utils/assets/checkout/checkout.png';
import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import PaymentInfo from '../components/PaymentInfo/PaymentInfo';
const Checkout = () => {

    const bannerSectionInfo =
    {
       cardTitle: "We love that you are joining us today!",
       cardBodyText: ``,
       cardButtonText: "",                    
       lengendBackground: checkoutBannerImg
    }

    useEffect(() => {
        
        window.scroll(0,0);
        
    }, []);
    return (
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
           <NavegationInfo page="Checkout"/>
           <PaymentInfo/>
        </React.Fragment>
    );
};

export default Checkout;
           