import React, {useState, useEffect }from "react";
import './SubscriptionDescription.css';

const SubscriptionDescription = ({ props }) => {

     const [currentPath, setCurrentPath] = useState('');

     useEffect(() => {
         setCurrentPath(window.location.pathname);
     }, []);


    return (
        <div className="SubscriptionDescription__main-container" >
            <div className="SubscriptionDescription__main-title-container">
                <h1>Like to enroll?</h1>
            </div>
            <div className="SubscriptionDescription__main-description-container">
                <p>
                    <b>Get the best magazines in the world with this outstanding monthly membership service.</b> Take advantage of these
                    incredible benefits:
                </p>
            </div>
            <div className="SubscriptionDescription__main-icons-container">
                <div className="SubscriptionDescription__inner-icons-container">
                    {
                        props.imgs.map((img, index)=>(
                            <div key={index} className="SubscriptionDescription__inner-icon-container">
                                <div className="SubscriptionDescription__icon-container">
                                    <img src={img.img} alt={index} className="SubscriptionDescription__icon"/>
                                </div>
                                <div className="SubscriptionDescription__description-container">
                                    <p>
                                        {img.description}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </div>
    );
};

export default SubscriptionDescription;