import React, { useEffect } from "react";

import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import SubscriptionPlan from "../components/SubscriptionPlan/SubscriptionPlan";
import ProductDescription from "../../shared/components/ProductDescription/ProductDescription";
import ContactUs from '../../Contact/components/ContactUs/ContactUs';


import manReadinfBannerImg from '../../utils/assets/home/Man-reading-a-magazine.png';
import readingMagazine from '../../utils/assets/aboutUs/man-reading-a-magazine.png';
import rippedPaper from '../../utils/assets/contact/rippder-paper.png';
import BrandImgSection from "../components/BrandImgSection/BrandImgSection";
import Faq from "../../AboutUs/components/Faq/Faq";


const Home = props => {

    useEffect(() => {
        let sectionName = sessionStorage.getItem('sectionName');
        if (sectionName === null) sectionName = "home";
        const sectionElement = document.getElementById(sectionName);
        if (sectionElement) {
            const windowHeight = window.innerHeight;
            const docScrollTop = window.scrollY || document.documentElement.scrollTop;
            const sectionTop = sectionElement.getBoundingClientRect().top + docScrollTop;
            const centeredPosition = sectionTop - windowHeight / 2;
            const scrollToPosition = centeredPosition + 310;
            window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
        }
        
    }, []);
    const bannerSectionInfo =
    {
        cardTitle: "Get the magazines you love delivered to your door!",
        cardBodyText: ``,
        cardButtonText: "join us",
        lengendBackground: manReadinfBannerImg
    }




    const productDescription = {
        title: "Bestselling magazines at unbelievable prices",
        img: readingMagazine

    }


    return (
        <React.Fragment>
            <WelcomeSection props={bannerSectionInfo} sectionId="home" />
            <NavegationInfo page="About us" />
            <ProductDescription props={productDescription} sectionId="about-us" />
            <BrandImgSection />
            <section style={{ backgroundImage: `url(${rippedPaper})`, padding: '2em' }} >
                <NavegationInfo page="Contact" />
                <ContactUs sectionId="contact-us" props={"contact"}/>
            </section>
            <NavegationInfo page="Price" />
            <SubscriptionPlan sectionId="price" />
            {/* <NavegationInfo page="FAQ" /> */}
            <Faq sectionId="faq" />
        </React.Fragment>
    );
};

export default Home;