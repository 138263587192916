import React, { useState } from "react";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import './CancelationForm.css'
import error from "../../../utils/helpers/errors/formErrors";
import validate from "../../../utils/helpers/validators/dataValidation";
import { DATA_COMES_FROM } from "../../../utils/helpers/constants/formValue";
import { REASON_DESCRIPTIONS } from "../../../utils/helpers/constants/cancellationReasons";
import Loader from "../../../shared/components/Loader/Loader";
import {sendCancelServiceEmail} from '../../../utils/helpers/services/cancelService';
import { SERVER_RESPONSE } from "../../../utils/helpers/constants/serverResponse";
const CancelationForm = props => {

    let styleForUnconfirmRecaptcha = {
        backgroundColor : '#e6e5e5',
        border : '1px solid #a63f03',
        color : '#000000'
    }
    let styleForConfirmRecaptcha = {
        backgroundColor : '#a63f03',
        border : 'none',
        color : '#ffffff'
    }
    const [recaptcha, setRecaptcha] = useState(false);
    const [loading, setLoading] = useState(false);
    //form validations
    const [errorState, setErrorState] = useState({
        orderId: false,
        phoneNumber: false,
        reasonForCancellation: false,

    });

    const [errorMessages, setErrorMessages] = useState({
        orderId: '',
        phoneNumber: '',
        reasonForCancellation: '',

    });

    const [formData, setFormData] = useState({
        orderId: '',
        phoneNumber: '',
        reasonForCancellation: '',


    });

    const handleFormValues = (e) => {
        const { name, value } = e.target;
        const {isValid, theError} = error.validateField(name, value);
        setErrorState((prevErrorState) => ({ ...prevErrorState, [name]: !isValid }));
        setErrorMessages((prevErrorMessages) => ({ ...prevErrorMessages, [name]: isValid ? '' : theError }));
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        let isFormInvalid = {
            isValid: true,
            theError: ''
        }
        if (validate.requieredFieldsToSentData(DATA_COMES_FROM.CANCEL_SERVICE_FORM, formData) === false) {

            Swal.fire({
                title: 'Error!',
                text: 'You must fill out the fields to continue',
                icon: 'error',
                confirmButtonText: 'Sure!',
                confirmButtonColor: '#a63f03'
            });
            return;

        }
        validate.allFieldsBeforeTheCheckout(formData, DATA_COMES_FROM.CANCEL_SERVICE_FORM).forEach((response) => {
            if (response.isValid === false) {
                isFormInvalid = {
                    isValid: response.isValid,
                    theError: response.typeError
                }
            }
        });

        if (isFormInvalid['isValid']) {
            setLoading(true);
            sendCancelServiceEmail(formData)
            .then( result =>{
            setLoading(false);
              
                if( result === SERVER_RESPONSE.SUCCESS){
                    Swal.fire({
                        title: "Message sent!",
                        text: "One our representatives will contact you shortly",
                        icon: "success",
                        confirmButtonColor: '#a63f03'
                    }).then((result) => {
                        if (result.isConfirmed || result.isDismissed) {
                            setFormData({
                                orderId: '',
                                phoneNumber: '',
                                reasonForCancellation: '',
                            });
                            setErrorState({
                                orderId: false,
                                phoneNumber: false,
                                reasonForCancellation: false,
                            });
                            setErrorMessages({
                                orderId: '',
                                phoneNumber: '',
                                reasonForCancellation: '',
                            });
                            window.open('/', '_self');
                        }
                    });
                }
                else{
                    setLoading(false);
                    Swal.fire({
                      title: "Error",
                      text: "Error in the server. Try again, please",
                      icon: "error",
                      confirmButtonColor : '#a63f03'
                    })
                  }
            })
            .catch( error =>{
                console.log(error);
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Error sending the cancellation email. Please, try again",
                    icon: "error",
                    confirmButtonColor : '#a63f03'
                  })
            });

           
        }
        else{
            Swal.fire({
                title: 'Error!',
                text: `${isFormInvalid['theError']}`,
                icon: 'error',
                confirmButtonText: "I am going to change it",
                confirmButtonColor: '#a63f03'
              });
              return;
        }

        

    }

    const resetDataFormValues = () => {
        setFormData({
            orderId: '',
            phoneNumber: '',
            reasonForCancellation: '',
        });
        setErrorState({
            orderId: false,
            phoneNumber: false,
            reasonForCancellation: false,
        });
        setErrorMessages({
            orderId: '',
            phoneNumber: '',
            reasonForCancellation: '',
        });
    }


    return (
        loading ? <Loader isLoading={loading}></Loader> :
        (
            <div className="CancelationForm__main-form-container">
            <form onSubmit={handleOnSubmit}>

                <div className="mb-0">
                    <label htmlFor="orderId" className="form-label">Order ID</label>
                    <input
                        type="text"
                        className="form-control"
                        id="orderId"
                        name="orderId"
                        aria-describedby="orderId"
                        placeholder="1237AB"
                        onChange={handleFormValues}
                        value={formData.orderId}
                        required autoComplete="off" />

                    {errorState.orderId && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.orderId}</span>}
                </div>

                <div className="mb-0 mt-3">
                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                    <input type="text"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="(+1) xxx xxx xxxx"
                        onChange={handleFormValues}
                        value={formData.phoneNumber}
                        required autoComplete="off" />
                    {errorState.phoneNumber && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.phoneNumber}</span>}
                </div>

                <label htmlFor="reasonForCancellation" className="form-label mt-3">Reason for cancellation</label>
                <select className="form-select mb-3 mt-2"
                    id="reasonForCancellation"
                    name="reasonForCancellation"
                    onChange={handleFormValues}
                    value={formData.reasonForCancellation}
                    aria-label="Default select example"
                    required
                    autoComplete="off">

                    <option value='' disabled>
                        Choose your reason
                    </option>
                    {REASON_DESCRIPTIONS.map((reason, index) => (
                        <option key={index} value={reason}>
                            {reason}
                        </option>
                    ))}
                </select>
                {errorState.reasonForCancellation && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.reasonForCancellation}</span>}
                <div className="ContactForm__recaptcha-container">
                        <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    onChange={(val)=> setRecaptcha(val)}
                    />
                    </div>
                <div className="ContactForm__button-container" style={{ width: '100%' }}>
                    <button type="button" className=" ContactForm__btn mt-2 mb2" onClick={resetDataFormValues}>Reset</button>
                    <button type="submit" className=" ContactForm__btn ContactForm__send-btn mt-2 mb2" disabled={!recaptcha} style={recaptcha ? styleForConfirmRecaptcha: styleForUnconfirmRecaptcha }>Send</button>
                </div>

            </form>
        </div>
        )
    );
};

export default CancelationForm;