import { API } from '../../helpers/constants/apiUrl';
import {WEBSITE} from '../../helpers/constants/websiteData'

export const sendContactEmail = (dataFormForm) => {
    const fullMessage = `The full name of the customer is: ${dataFormForm.firstName} \n
                         The phone number is: ${dataFormForm.phoneNumber}\n
                         The email is: ${dataFormForm.email}\n
                         The message is:\n
                         ${dataFormForm.message}
                        `
    const formData = new FormData();
    formData.append('website', WEBSITE.DOMAIN);
    formData.append('message', fullMessage);
    formData.append('TOKEN', process.env.REACT_APP_CONTACT_TOKEN);

    return fetch(API.CONTACT_FORM, {
        method: 'POST',
        body: formData,
    })
        .then(response => response.text())
        .then(data => {
            return data; 
        })
        .catch(error => {
            console.error('Error:', error);
            throw error; 
        });
}