import React from "react";
import './MembershipTerms.css'

import ContactCard from '../../../shared/components/ContactCard/ContactCard';

const MembershipTerms = props => {


    return (
        <>
            <section className="Membership__main-section-container">
                <div>
                    <h1 className="text-center Membership__main-title"> Terms of membership</h1>
                </div>
                <div className="Membership__section-description">
                    <p>
                        <b>
                        BY PLACING AN ORDER FOR OUR MAGAZINES PRODUCTS AND OR ENROLLING IN OUR INTERNATIONAL READERS, MAGAZINE 
                        MEMBERSHIP SERVICE (“CLUB”) YOU AGREE, ACCEPT AND UNDERSTAND THE TERMS OF THE OFFER BELOW (“AGREEMENT”). WE URGE  
                        YOU TO READ THE TERMS OF OFFER CAREFULLY AND, IF YOU HAVE ANY QUESTIONS, CALL OUR CUSTOMER SERVICE REPRESENTATIVES AT
                        888-413-1548.
                        </b>
                    </p>
                    <p></p>
                    <p>
                    Membership in the Club is open to individuals who are legal residents of the continental U.S. (excluding Hawaii, Alaska and the U.S. Virgin Islands), who
                    are at least age 18 as of the date of enrollment and have the capacity to enter into a binding contract. The Club is void elsewhere and where prohibited.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        1.ENROLLING IN OUR CLUB PROGRAM: :  
                    </p>
                    <p>In placing your order for International Readers llc you are automatically enrolled into our International Readers, Magazine Savings Club.</p>
                    <p></p>
                    <p className="text-decoration-underline">
                        2. CANCELLING YOUR MEMBERSHIP: 
                    </p>
                    <p>
                    If you do not take affirmative action to cancel the Club membership, your credit or debit card will be automatically charged the monthly club price of 
                    $29.95 and you will continue to be a member of the Club. To cancel your membership call our Customer Service Department at <b>888-413-1548.</b>
                    </p>
                    <p></p>
                    <p className="text-decoration-underline"> 
                        3. 30 DAY MONEY BACK GUARANTEE:
                    </p>
                    <p>
                    We want you to be fully satisfied with your enrollment in the International Readers . If you are not satisfied, you may call customer service within 30
                    days from the date of the billing of your first monthly fee for a full refund of the monthly fee.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        4. AUTOMATIC BILLINGS: 
                    </p>
                    <p>
                    As a member of the Club you will have the ability to choose up to four titles of the magazines you like the most and have them mailed to the address of
                    your preference at the monthly club price of $29.95 monthly if you ever want to change you can select from our list of title by simply calling customer 
                    service <b>888-413-1548</b> As a member of the club the credit or debit card you provide today will be automatically charged the Club price for the next 30
                    days of service. You will continue to be charged each membership month for the following 30 days of service until you contact customer service to
                    cancel.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        5. HOW TO CANCEL: 
                    </p>
                    <p>
                    Call our Customer Service Department at <b>888-413-1548</b> or by notifying us in writing at customer service, <b>International Readers llc  312 W 2nd St
Unit #A4819
Casper, WY 82601</b>
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        6. NOTICE OF PRICE CHANGE:  
                    </p>
                    <p>
                    As a member of the Club you have the right to receive written notice of all price increases that vary from the amount you previously authorized. If we 
                    decide to increase the price, we will notify you via email or mail and give you an opportunity to cancel your membership before such changes take effect.
                    However, we do reserve the right to lower your membership price at any point in time without notification.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        7. MONTHLY PAYMENT: 
                    </p>
                    <p>
                    All monthly charges will be automatically charged to the credit or debit card you provided upon enrollment International Readers llc is not responsible for
                    any fees or other charges (including, without limitation, overdraft fees charged by your bank) resulting from the charges you have authorized in this 
                    Agreement.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        8. PRODUCTS AND SERVICES: 
                    </p>
                    <p>
                    (1) Up to Four Magazine titles of your preferences; (a) From time to time you will be granted the option to receive up to four titles for no additional cost.
                    (b) You have the ability to split the titles between friends or family member simply call customer service and we can ship out the magazine at the address
                    you desire (c) You can change the title at any point in time, simply call customer service at <b>888-413-1548.</b>
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        9. USE OF CLUB MEMBERSHIP:
                    </p>
                    <p>
                    Your Club membership is non-transferable. You agree that only you and your Immediate Family may use the Club membership_ “Immediate Family” means 
                    you, your spouse or partner and your children living at your home. Participation in the Club program is subject to this Agreement, as well as policies and
                    procedures that International Readers may adopt or modify from time to time. Any failure to abide by this Agreement or any policies or procedures 
                    implemented by International Readers any conduct detrimental to International Readers LLC, or any misrepresentation or fraudulent activities in
                    connection with the Club program, may result in termination of membership in the Club program, as well as any other rights or remedies available to
                    International Readers LLC. If we suspend or terminate membership for any reason specified in this Section 9, we reserve the right to not refund any fees
                    paid by you.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        10. MODIFICATION OR TERMINATION OF THE CLUB: 
                        
                    </p>
                    <p>
                    Your membership in the Club is offered at the discretion of International Readers LLC, and it reserves the right to modify these terms and conditions,
                        condition of participation, or any other aspect of the Club, in whole or in part, at any time, with or without notice to you (except as otherwise specified
                        herein).
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        11. LIMITATION ON LIABILITY AND DISCLAIMER OF WARRANTIES: 
                    </p>
                    <p>
                    By placing an order and/or participating in the Club, you agree that International Readers LLC and its owner(s), parent, subsidiaries, affiliates, agents,
                    representatives, and employees will have no liability whatsoever for any injuries, losses, claims, damages or any special, exemplary, punitive, Indirect,
                    incidental or consequential damages of any kind, whether based in contract, tort, strict liability or otherwise, resulting from any use Club or the 
                    International Readers LLC products and services, any failure or delay by International Readers in connection with the Club, the performance or non-
                    performance of the Club by International Readers LLC, or any other products or services provided by International Readers, its licensors, or any other 
                    third parties, even if International Readers LLC has been advised of the possibility of damages. Notwithstanding this disclaimer, if International Readers
                    LLC is found liable for any loss or damage which arises out of, or is in any way connected with, any of the occurrences described in this paragraph, then
                    its liability will in no event exceed, in total, the sum of $100.00.
                    </p>
                    <p></p>
                    <p>
                    International Readers LLC, makes no warranty of any kind regarding the Club or the International Readers LLC products or services, which are provided on
                    an “as is” and “as available” basis. International Readers LLC is not responsible or liable for any warranty, representation, or guarantee, express or implied,
                    in fact or in law, relative to the Club or the International Readers products and services, including without limitation that the Club or International Readers
                    LLC, products and services will be error-free, or as to the accuracy, completeness and timeliness of any content or information distributed with respect to
                    the Club or International Readers LLC products and services. <b>SOME STATES DO NOT ALLOW THE LIMITATION OF LIABILITY AND DISCLAIMER OF
                    IMPLIED WARRANTIES, SO THE DISCLAIMERS AND LIMITATIONS ABOVE MAY NOT APPLY TO YOU.
                    </b>
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        12. ENTIRE AGREEMENT: 
                    </p>
                    <p>
                    This Agreement contains all of the terms of the Club program, and no representations, inducements, promises or agreements concerning the Club 
                    program not included in this Agreement shall be effective or enforceable. If any of the terms of this Agreement shall become invalid or unenforceable, the
                    remaining terms shall remain in full force and effect.                   
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>GOVERNING LAW:</b>
                    </p>
                    <p>
                        <b>THIS AGREEMENT AND THE TERMS OF THE CLUB MEMBERSHIP SHALL BE GOVERNED AND CONSTRUED IN ACCORDANCE WITH THE LAWS 
                        OF THE STATE OF WYOMING WITHOUT GIVING EFFECT TO THE CHOICE OF LAW PROVISIONS THEREOF.
                        </b>
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>ARBITRATION: </b>
                    </p>
                    <p>
                    <b>
                    ANY CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO YOUR USE OF INTERNATIONAL READERS LLC WEB SITE, YOUR
                    MEMBERSHIP IN THE CLUB, YOUR USE OF INTERNATIONAL READERS LLC PRODUCTS. OR TO ANY ACT OR OMISSION FOR WHICH YOU MAY
                    CONTEND THAT INTERNATIONAL READERS LLC IS LIABLE, INCLUDING BUT NOT LIMITED TO ANY CLAIM OR CONTROVERSY AS TO
                    ARBITRABILITY (“DISPUTE”), SHALL BE FINALLY AND EXCLUSIVELY SETTLED BY BINDING ARBITRATION. JUDGMENT UPON ANY AWARD 
                    RENDERED BY THE ARBITRATOR MAY BE ENTERED BY ANY STATE OR FEDERAL COURT HAVING JURISDICTION WYOMING THE ARBITRATOR
                    SHALL NOT HAVE THE POWER TO AWARD DAMAGES IN CONNECTION WITH ANY DISPUTE IN EXCESS OF ACTUAL COMPENSATORY
                    DAMAGES AND SHALL NOT MULTIPLY ACTUAL DAMAGES OR AWARD CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, AND EACH
                    PARTY IRREVOCABLY WAIVES ANY CLAIM THERETO. NEITHER YOU NOR USA BENEFITS ADVANTAGE SHALL BE ENTITLED TO JOIN OR
                    CONSOLIDATE CLAIMS IN ARBITRATION BY OR AGAINST OTHER CONSUMERS OR ARBITRATE ANY CLAIM AS A REPRESENTATIVE OR MEMBER
                    OF A CLASS OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. BOTH YOU AND SAVINGS AND BENEFITS VOLUNTARILY AND KNOWINGLY
                    WAIVE ANY RIGHT THEY HAVE TO A JURY TRIAL.
                    </b>
                    </p>
                    <p></p>
                    <p>
                    <b>
                    THESE TERMS PROVIDE THAT ALL DISPUTES BETWEEN YOU AND INTERNATIONAL READERS LLC WILL BE RESOLVED BINDING ARBITRATION.
                    YOU THUS GIVE UP YOUR RIGHT TO GO TO COURT TO ASSERT OR DEFEND YOUR RIGHTS. YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE 
                    IN OR BRING CLASS ACTIONS. YOUR RIGHTS WILL BE DETERMINED BY NEUTRAL ARBITRATOR(S) NOT A JUDGE OR JURY.
                    </b>
                    </p>

                </div>
            </section>

            <section className="MembershipTerms__customer-service-container">
                <div className="MembershipTerms__customer-service">
                     <ContactCard />
                </div>
            </section>
        </>
    );
};

export default MembershipTerms;