import React from "react";
import './SubscriptionPlan.css';
import SubscriptionCard from '../../../shared/components/SubscriptionCard/SubscriptionCard';
import SubscriptionDescription from '../../../Price/components/SubscriptionDescription/SubscriptionDescription';
import assistenceIcon from '../../../utils/assets/price/icons/assistence-icon.png';
import friendIcon from '../../../utils/assets/price/icons/friend-icon.png';
import moveIcon from '../../../utils/assets/price/icons/move-icon.png';
import policyTermsIcon from '../../../utils/assets/price/icons/policy-terms-icon.png';
import refundPolicyIcon from '../../../utils/assets/price/icons/refund-icon.png';
import titleIcon from '../../../utils/assets/price/icons/title-icon.png';






const SubscriptionPlan = props => {

    const subscriptionCardInformation = {
        subscriptionType: "Join Us",
        subscriptionPrice: "29.95",
        subscriptionTrialInfo: "",
        subscriptionDescription: "Monthly",
        subscriptionButtonText: "Buy",
    }

    const subscriptionInformation = {
        imgs: [
            { img: friendIcon, description: "SHARE TITLES" },
            { img: refundPolicyIcon, description: "EASY REFUND AND CANCEL" },
            { img: titleIcon, description: "TITLE CHANGE IS EASY" },
            { img: moveIcon, description: "UPDATE YOUR ADDRESS" },
            { img: policyTermsIcon, description: "CRYSTAL-CLEAR POLICIES" },
            { img: assistenceIcon, description: "24/7/365 ASSISTANCE" },
        ]
    }





    return (
        <section className="SubscriptionPlan__main-section-container" id="price">
            <div className="SubscriptionPlan__subscription-plans-container">
                <div className="SubscriptionPlan__subscription-description-component-container">
                    <SubscriptionDescription props={subscriptionInformation} />
                </div>
                <div className="SubscriptionPlan__subscription-card-component-container">
                    <SubscriptionCard props={subscriptionCardInformation} />
                </div>
            </div>

        </section>
    );
};

export default SubscriptionPlan;