import React, { useEffect } from "react";


import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import notFoundImg from '../../utils/assets/notFound/notFoundImg.png';
import Error404 from "../components/Error404/Error404";
const NotFound = () =>{

    const WelcomeSectionInfo =
    {
       cardTitle: "It's all right. That's a mistake!",
       cardButtonText: "",
       lengendBackground: notFoundImg
    }
    useEffect(()=>{
        sessionStorage.clear();
    },[])


    return (
        <React.Fragment>
            <WelcomeSection props={WelcomeSectionInfo}/>
            <Error404/>

        </React.Fragment>
    );
}

export default NotFound;