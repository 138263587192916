import React, {useEffect} from "react";

import refundPolicyLegen from '../../utils/assets/refundPolicy/problems-with-an-item-return-policy.png';
import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";


import ReimbursementGuidelines from "../components/ReimbursementGuidelines/ReimbursementGuidelines";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";





const RefundPolicy = props => {
    const bannerSectionInfo =
    {
        cardTitle: "Trouble with a magazine?",
        cardBodyText: ``,
        cardButtonText: "",
        lengendBackground: refundPolicyLegen
    }
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
    return (
        <React.Fragment>
         
            <WelcomeSection props={bannerSectionInfo}/>
            <NavegationInfo page="Refund policy"/>
            <ReimbursementGuidelines />
       
            
           
            
        </React.Fragment>
    );
};

export default RefundPolicy;